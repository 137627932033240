import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './global.scss';
// import Header from './components/Header.tsx';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import ConsentDetails from './components/ConsentDetails';
import Thankyou from './components/Thankyou';
import PrivacyPolicy from './components/PrivacyPolicy';
import Dashboard from './components/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";



// Create a client
const queryClient = new QueryClient()
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#ec7c04'
      },
      secondary: {
        main: '#243474'
      }
    }
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="App">
          {/* <Header /> */}
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path=":consentId/thankyou" element={<Thankyou />} />
              <Route exact path=":consentId" element={<ConsentDetails />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
