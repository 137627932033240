import * as React from 'react';
import Button from '@mui/material/Button';
import { Modal } from '@mui/material';
import { Form } from 'react-final-form'
import * as Yup from 'yup';
import {makeValidate, DatePicker, TextField} from 'mui-rff';
import styles from './Authenticate.module.scss';
import {useQuery,useMutation,} from '@tanstack/react-query'
import axios from 'axios';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from 'react-router-dom';

const schema = Yup.object().shape({
  dob: Yup.string().required().matches(
    /^(0[1-9]|[12][0-9]|3[01])[-./](0[1-9]|1[012])[-./]\d{4}$|^\d{8}$/,
    'Please use DD-MM-YYYY, DD.MM.YYYY, DD/MM/YYYY, or DDMMYYYY'
  ),
});
 
const validate = makeValidate(schema);

function Authenticate({ openAuthenticate, setOpenAuthenticate, consentId }) {


  const [versionChecked, setVersionChecked] = React.useState(false);
  const [isLatest, setIsLatest] = React.useState(null); 

  const navigate = useNavigate(); // Initialize navigate


  const { isLoading: isCheckingVersion, error: versionError } = useQuery(
    ['checkIfLatestVersion', consentId],
    () => axios.get(`/patient/consent_bundle/${consentId}/check_if_latest_version`).then(res => res.data),
    {
      enabled: !versionChecked && !!consentId,
      onSuccess: (data) => {
        console.log(data)
        if (data === true) {
          return;
        } else {
          navigate(`/${data}`); 
        }
      }
    }
  );


  const authenticate = useMutation(
    (dob) => {
        return axios.get(`/patient/authenticate?consent_bundle_id=${consentId}&dob=${dob}`);
    },
    {
      onSuccess: (data) => {
        if (data.data) {
          setOpenAuthenticate(false)
        }
      }
    }
  );

  const sendHelpData = data => {
    authenticate.mutate(data.dob);
  }

  const handleKeyPress = (e) => {
    const allowedCharacters = /[0-9\-\/.]/;
    const key = e.key;
    if (key === 'Enter') {
      e.preventDefault(); 
      sendHelpData({ dob: e.target.value }); 
    } else if (!allowedCharacters.test(key)) {
      e.preventDefault();
    }
  };


  return (
      <Modal
        open={openAuthenticate}
      >
        <div className="modal">
          <h4>Please input your DOB to access the information compiled by your dentist</h4>
          <Form
            onSubmit={sendHelpData}
            validate={validate}
            render={({ handleSubmit, values }) => (
            <form className={styles.headerForm} onSubmit={handleSubmit} noValidate>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Birthday"
                  name="dob"
                  required={true}
                  inputFormat="dd/mm/yyyy"
                />
              </LocalizationProvider> */}
              <p><i>Please add your birthday in this format (DDMMYYYY)</i></p>
              <TextField
                label="Birthday"
                name="dob"
                required={true}
                onKeyPress={handleKeyPress}
              />
              <Button className={styles.submitButton} type="submit" variant="contained" disabled={authenticate.isLoading}>Authenticate</Button>
            </form>
            )}
        />
              <br />
              <a href="/privacy-policy" target="_blank">Privacy Policy</a>
        </div>
      </Modal> 
  );
}
export default Authenticate;