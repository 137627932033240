import * as React from 'react';
import {TextField, Radios, Checkboxes} from 'mui-rff';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Form } from 'react-final-form'
import {
  useMutation
} from '@tanstack/react-query'
import axios from 'axios';
import styles from './Thankyou.module.scss';

export default function Thankyou() {
  const [haveFeedback, setHaveFeedback] = React.useState(false)
  let { consentId } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const feedback = useMutation(
    (data) => {
        return axios.post(`/patient/feedback?consent_bundle_id=${consentId}`, data);
    },
    {
      onSuccess: (data) => {
        setHaveFeedback(true);
      }
    }
  );

  const sendFeedback = data => {
    feedback.mutate(data);
  }

  return (
    <div className={styles.thankyou}>
        <h1>Thank you</h1>
        <p className={styles.success}>Signed successfully</p>
        {!haveFeedback && (
          <>
            <p>Share your feedback in the form below to help us improve</p>
            <Form
                onSubmit={sendFeedback}
                initialValues={{voucher: false, message: ''}}
                render={({ handleSubmit, values }) => (
                <form className={styles.headerForm} onSubmit={handleSubmit} noValidate>
                  <Radios
                    label="It was helpful to have information about my treatment in video form."
                    name="helpful"
                    required={true}
                    className={styles.radio}
                    data={[
                      {label: '😞', value: '1'},
                      {label: '🙁', value: '2'},
                      {label: '😐', value: '3'},
                      {label: '🙂', value: '4'},
                      {label: '😃', value: '5'}
                    ]}
                  />
                  <Radios
                    label="I feel that I understand my treatment better?"
                    name="understand"
                    required={true}
                    className={styles.radio}
                    data={[
                      {label: '😞', value: '1'},
                      {label: '🙁', value: '2'},
                      {label: '😐', value: '3'},
                      {label: '🙂', value: '4'},
                      {label: '😃', value: '5'}
                    ]}
                  />
                  <Radios
                    label="I feel more in control of my treatment."
                    name="control"
                    required={true}
                    className={styles.radio}
                    data={[
                      {label: '😞', value: '1'},
                      {label: '🙁', value: '2'},
                      {label: '😐', value: '3'},
                      {label: '🙂', value: '4'},
                      {label: '😃', value: '5'}
                    ]}
                  />
                  <Radios
                    label="I feel more trust towards my dentist."
                    name="trust"
                    required={true}
                    className={styles.radio}
                    data={[
                      {label: '😞', value: '1'},
                      {label: '🙁', value: '2'},
                      {label: '😐', value: '3'},
                      {label: '🙂', value: '4'},
                      {label: '😃', value: '5'}
                    ]}
                  />
                  <Radios
                    label="I feel more relaxed going into my next appointment."
                    name="relaxed"
                    required={true}
                    className={styles.radio}
                    data={[
                      {label: '😞', value: '1'},
                      {label: '🙁', value: '2'},
                      {label: '😐', value: '3'},
                      {label: '🙂', value: '4'},
                      {label: '😃', value: '5'}
                    ]}
                  />
                  <Radios
                    label="I am  glad that my dentist sent me this educational material"
                    name="educational"
                    required={true}
                    className={styles.radio}
                    data={[
                      {label: '😞', value: '1'},
                      {label: '🙁', value: '2'},
                      {label: '😐', value: '3'},
                      {label: '🙂', value: '4'},
                      {label: '😃', value: '5'}
                    ]}
                  />
                  <TextField
                      label="What could be improved"
                      name="message"
                      type="text"
                      multiline
                      variant="outlined"
                      className="mg-b-1"
                  />
                  <Checkboxes name="voucher" data={{ label: 'I am happy for Medicube health to contact me to give more feedback for a chance to win a £150 Amazon voucher:', value: true }} />
                  {values.voucher && (
                    <>
                      <TextField
                          label="Name"
                          name="name"
                          type="text"
                          multiline
                          required={true}
                          variant="outlined"
                          className="mg-b-1"
                      />
                      <TextField
                          label="Phone"
                          name="phone"
                          type="text"
                          required={true}
                          multiline
                          variant="outlined"
                          className="mg-b-1"
                      />
                      <TextField
                          label="Email"
                          name="email"
                          required={true}
                          type="text"
                          multiline
                          variant="outlined"
                          className="mg-b-1"
                      />
                    </>
                  )}
                  {feedback.isError && <p className={styles.error}>Something went wrong. Please fill all required fields and try again.</p>}
                  <div className={styles.buttonGroup}>
                    <Button
                      className={styles.submitButton}
                      type="button"
                      variant="contained"
                      disabled={feedback.isLoading}
                      onClick={() => {
                        setHaveFeedback(true);
                      }}
                    >Skip</Button>
                    <Button className={styles.submitButton} type="submit" variant="contained" disabled={feedback.isLoading}>Submit feedback</Button>
                  </div>
                </form>
                )}
            />
          </>
        )}
    </div>
  );
}