import * as React from 'react';


function PrivacyPolicy() {
  return (
      <div style={{padding: 16}}>
        <h2>Medicube health Privacy Policy</h2>
        <h3>Our contact details</h3>
        <p>Name: Medicube health</p>
        <p>E-mail: contact@medicube.health</p>
        <br />
        <p>At Medicube health, we are committed to safeguarding your privacy and
            protecting your personal information. This privacy policy explains how we
            collect, use, and securely store your personal data.
        </p>
        <h3>The type of personal information we collect</h3>
        <p>We currently collect and process the following information:</p>
        <ul>
            <li>Personal identifiers, contacts and characteristics (for example, name and contact details)</li>
            <li>Dental treatment codes</li>
        </ul>
        <h3>How we get the personal information and why we have it</h3>
        <p>Most of the personal information we process is provided to us for one of the following reasons:</p>
        <ul>
            <li>To compile dental consent documents</li>
            <li>To assist communications with your dentist by selecting relevant educational content for you.</li>
        </ul>
        <p>We use the information for the following purposes:</p>
        <ul>
            <li>To compile a medical consent document</li>
            <li>To assist the dentist in their communications with you by selecting relevant educational content for you.</li>
        </ul>
        <br />
        <p>We will only share this information with your dental provider (dentist). Under the UK General Data Protection Regulation (UK GDPR), the lawful bases we rely on for processing this information are:</p>
        <b>(b) We have a contractual obligation.</b>
        <h3>How we store your personal information</h3>
        <p>Your personal information is securely stored and maintained in an encrypted format to protect your privacy. We retain your data for 48 hours, after which it will be securely and permanently deleted. We follow stringent data disposal procedures to ensure the complete removal of your information.</p>
        <h3>Your data protection rights</h3>
        <p>Under data protection law, you have rights including:</p>
        <p><b>Your right of access - </b>You have the right to ask us for copies of your personal information.</p>
        <p><b>Your right to data portability - </b>You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</p>
        <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you. Please contact us at contact@medicube.health if you wish to make a request.</p>
        <h3>How to complain</h3>
        <p>If you have any concerns about our use of your personal information, you can make a complaint to us at contact@medicube.health</p>
        <p>You can also complain to the ICO if you are unhappy with how we have used your data.</p>
        <p>The ICO’s address:</p>
        <p>Information Commissioner’s Office</p>
        <p>Wycliffe House
        <br />Water Lane
        <br />Wilmslow
        <br />Cheshire
        <br />SK9 5AF</p>
        <br />
        <p>Helpline number: 0303 123 1113</p>
        <p>ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></p>
      </div>
  );
}
export default PrivacyPolicy;